<template>
  <div class="user-center">
    <el-card class="box-card" v-loading="loading">
      <h3>用户信息</h3>
      <div class="wrap">
        <div class="item">
          <div class="label">用户名：</div>{{info.username}}
        </div>
        <div class="item">
          <div class="label">有效期：</div>
          <template v-if="info.from">{{info.from * 1000 | dataFormat('yyyy-mm-dd')}}</template>
          <template v-else>-</template>
          ~
          <template v-if="info.to">{{(info.to + 1 - 60 * 60 * 24) * 1000 | dataFormat('yyyy-mm-dd')}}</template>
          <template v-else>-</template>
        </div>
      </div>
      <h3>店铺信息</h3>
      <div class="wrap">
        <div class="item">
          <div class="label">店铺Id：</div>{{info.shopId}}
        </div>
        <div class="item">
          <div class="label">店铺名称：</div>{{info.shopName}}
        </div>
      </div>
      <div class="wrap">
        <div class="item">
          <div class="label">API Key：</div>{{info.apiKey}}
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      info: {},
      loading: true,
      shopList: []
    }
  },
  async created() {
    this.info = await this.getUserInfo()
    this.loading = false
  },
  methods: {
    ...mapActions(['getUserInfo']),
  }
}
</script>
<style lang="less" scoped>
.user-center {
  .wrap {
    display: flex;
    margin: 25px 0;
    .item {
      width: 50%;
      display: flex;
      align-items: center;
      font-size: 14px;
      .label {
        width: 70px;
        text-align: right;
      }
    }
  }
}
</style>